import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";

const RegisterScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  const { userInfo } = useSelector((state) => state.auth); // get user info from the auth
  const { search } = useLocation();
  const seachParams = new URLSearchParams(search);
  const redirect = seachParams.get("redirect") || "/"; // nativage to either index page or whatever is in the index such as shipping

  // check to see if we are already logged in
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        // lets call the register mutation
        const res = await register({ email, password, name }).unwrap();
        dispatch(setCredentials({ ...res })); // set the credentials in the auth slice
        toast.success("You are now logged in!");
        navigate(redirect); // navigate to the redirect page
      } catch (err) {
        console.log(err);
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <div className=" w-full max-w-xl mx-auto mt-[2rem] p-2">
      <h1 className="text-3xl text-center text-custom-orange font-semibold mb-4">
        Sign Up
      </h1>
      <form onSubmit={submitHandler} className="text-lg space-y-4">
        {/* Name */}
        <div className="flex flex-col">
          <label htmlFor="name" className="text-custom-orange font-semibold">
            Name
          </label>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        {/* Email */}
        <div className="flex flex-col">
          <label htmlFor="email" className="text-custom-orange font-semibold">
            Email
          </label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        {/* Password */}
        <div className="flex flex-col">
          <label
            htmlFor="password"
            className="text-custom-orange font-semibold"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        {/* Confirm Password */}
        <div className="flex flex-col">
          <label
            htmlFor="confirmPassword"
            className="text-custom-orange font-semibold"
          >
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="mt-2 px-3 py-[4px] rounded-md bg-custom-orange text-white hover:cursor-pointer hover:scale-105 transition-all"
            disabled={isLoading}
          >
            Sign Up
          </button>
        </div>
        <p className="flex justify-center items-center text-black">
          Already have an account?{" "}
          <Link
            className="text-custom-orange font-semibold ml-2"
            to={redirect ? `/login?redirect=${redirect}` : "/login"}
          >
            Login
          </Link>
        </p>
        {isLoading && <Loader />}
      </form>
    </div>
  );
};

export default RegisterScreen;

// <FormContainer>
//   <h1>Sign Up</h1>

//   <Form onSubmit={submitHandler}>
//     <Form.Group controlId="name" className="my-3">
//       <Form.Label>Name</Form.Label>
//       <Form.Control
//         type="text"
//         placeholder="Enter Name"
//         value={name}
//         onChange={(e) => setName(e.target.value)}
//       ></Form.Control>
//     </Form.Group>

//     <Form.Group controlId="email" className="my-3">
//       <Form.Label>Email Address</Form.Label>
//       <Form.Control
//         type="email"
//         placeholder="Enter Email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       ></Form.Control>
//     </Form.Group>

//     <Form.Group controlId="password" className="my-3">
//       <Form.Label>Password</Form.Label>
//       <Form.Control
//         type="password"
//         placeholder="Enter Password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//       ></Form.Control>
//     </Form.Group>

//     <Form.Group controlId="confirmpassword" className="my-3">
//       <Form.Label>Confirm Password</Form.Label>
//       <Form.Control
//         type="password"
//         placeholder="Confirm Password"
//         value={confirmPassword}
//         onChange={(e) => setConfrimPassword(e.target.value)}
//       ></Form.Control>
//     </Form.Group>

//     <Button
//       type="submit"
//       variant="primary"
//       className="mt-2"
//       disabled={isLoading}
//     >
//       Sign Up
//     </Button>

//     {/* When we try to login */}
//     {isLoading && <Loader />}
//   </Form>

//   <Row className="py-3">
//     <Col>
//       Already have an account?{" "}
//       <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
//         Login
//       </Link>
//     </Col>
//   </Row>
// </FormContainer>
