import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { savePaymentMethod } from "../slices/cartSlice";
import { Form, Button, Col } from "react-bootstrap";
import CheckoutSteps from "../components/CheckoutSteps";
import FormContainer from "../components/FormContainer";
import "../assets/styles/index.css";


const PaymentScreen = () => {
  const [paymentMethod, setPaymentMethod] = useState("Cash On Delivery");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  useEffect(() => {
    if (!shippingAddress) navigate("/shipping");
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <FormContainer>
      <CheckoutSteps step2 step3 />
      <h1 className="text-3xl text-custom-orange font-semibold mb-4">
        Payment Method
      </h1>

      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label as="legend" className="text-custom-orange font-normal">
            Select Method
          </Form.Label>
          <Col>
            {/* <Form.Check
              type="radio"
              className="my-2"
              label="PayPal or Credit Card"
              id="PayPal"
              name="paymentMethod"
              value="PayPal"
              checked={paymentMethod === "PayPal"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            /> */}
            <Form.Check
              type="radio"
              className="my-2 text-black font-medium"
              label="Cash On Delivery"
              id="CashOnDelivery"
              name="paymentMethod"
              value="Cash On Delivery"
              checked={paymentMethod === "CashOnDelivery"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Button
          type="submit"
          className="mt-2 px-3 py-[8px] rounded-md bg-custom-orange text-white hover:cursor-pointer hover:scale-105 transition-all border-none hover:bg-orange-600"
        >
          Continue
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;

// <FormContainer>
//       <CheckoutSteps step1 step2 step3 />
//       <h1 className="text-3xl text-custom-orange font-semibold mb-4">
//         Payment Method
//       </h1>

//       <Form onSubmit={submitHandler}>
//         <Form.Group>
//           <Form.Label as="legend">Select Method</Form.Label>
//           <Col>

//             <Form.Check
//               type="radio"
//               className="my-2"
//               label="PayPal or Credit Card"
//               id="PayPal"
//               name="paymentMethod"
//               value="PayPal"
//               checked={paymentMethod === "PayPal"}
//               onChange={(e) => setPaymentMethod(e.target.value)}
//             />

//             <Form.Check
//               type="radio"
//               className="my-2"
//               label="Cash On Delivery"
//               id="CashOnDelivery"
//               name="paymentMethod"
//               value="Cash On Delivery"
//               checked={paymentMethod === "CashOnDelivery"}
//               onChange={(e) => setPaymentMethod(e.target.value)}
//             />
//           </Col>
//         </Form.Group>

//         <Button type="submit" variant="primary" className="my-3">
//           Continue
//         </Button>
//       </Form>
//     </FormContainer>
