import { apiSlice } from './apiSlice';
import { LEDGER_URL } from '../constants';

export const ledgerApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLedgerEntries: builder.query({
            query: () => ({
                url: LEDGER_URL,
            }),
            keepUnusedDataFor: 5,
        }),
        addLedgerEntry: builder.mutation({
            query: (ledgerData) => ({
                url: LEDGER_URL,
                method: 'POST',
                body: ledgerData,
            }),
            invalidatesTags: ["Ledger"],
        }),
        deleteLedgerEntry: builder.mutation({
            query: (id) => ({
                url: `${LEDGER_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Ledger"],
        }),
        returnLedgerEntry: builder.mutation({
            query: (id) => ({
                url: `${LEDGER_URL}/${id}/return`,
                method: 'PUT',
            }),
            invalidatesTags: ["Ledger"],
        }),
        updateLedgerEntry: builder.mutation({
            query: ({ id, ledgerData }) => ({
                url: `${LEDGER_URL}/${id}`,
                method: 'PUT',
                body: ledgerData,
            }),
            invalidatesTags: ["Ledger"],
        }),
    }),
});

export const {
    useGetLedgerEntriesQuery,
    useAddLedgerEntryMutation,
    useDeleteLedgerEntryMutation,
    useReturnLedgerEntryMutation,
    useUpdateLedgerEntryMutation,
} = ledgerApiSlice;
