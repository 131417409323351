import React, { useState } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import { useGetLedgerEntriesQuery, useAddLedgerEntryMutation, useDeleteLedgerEntryMutation, useReturnLedgerEntryMutation, useUpdateLedgerEntryMutation } from '../../slices/ledgerApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const LedgerScreen = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    productName: '',
    productCategory: '',
    productBrand: '',
    productSize: '', // Added productSize
    productCost: 0,
    productSellingPrice: 0,
    deliveryCharges: 0,
    miscellaneousCost: 0,
    status: 'Sold', // Default status
  });
  const [editingEntryId, setEditingEntryId] = useState(null);

  const { data: ledgerEntries, isLoading, error, refetch } = useGetLedgerEntriesQuery();
  const [addLedgerEntry, { isLoading: addLoading }] = useAddLedgerEntryMutation();
  const [deleteLedgerEntry, { isLoading: deleteLoading }] = useDeleteLedgerEntryMutation();
  const [returnLedgerEntry, { isLoading: returnLoading }] = useReturnLedgerEntryMutation();
  const [updateLedgerEntry, { isLoading: updateLoading }] = useUpdateLedgerEntryMutation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Update status based on selling price
    formData.status = formData.productSellingPrice > 0 ? 'Sold' : 'Returned';

    try {
      if (editingEntryId) {
        await updateLedgerEntry({ id: editingEntryId, ledgerData: formData });
      } else {
        await addLedgerEntry(formData);
      }
      refetch(); // Refetch the data to update the UI
      setFormData({
        productName: '',
        productCategory: '',
        productBrand: '',
        productSize: '', // Reset productSize
        productCost: 0,
        productSellingPrice: 0,
        deliveryCharges: 0,
        miscellaneousCost: 0,
        status: 'Sold',
      });
      setEditingEntryId(null);
      handleClose();
    } catch (error) {
      console.error('Failed to submit entry', error);
    }
  };

  const handleEdit = (entry) => {
    setFormData(entry);
    setEditingEntryId(entry._id);
    handleShow();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure?')) {
      try {
        await deleteLedgerEntry(id);
        refetch(); // Refetch the data to update the UI
      } catch (error) {
        console.error('Failed to delete entry', error);
      }
    }
  };

  const handleReturn = async (id) => {
    if (window.confirm('Are you sure you want to mark this as returned?')) {
      try {
        await returnLedgerEntry(id);
        refetch(); // Refetch the data to update the UI
      } catch (error) {
        console.error('Failed to return entry', error);
      }
    }
  };

  return (
    <>
      <h1>Ledger</h1><br></br>
      <Button variant="primary" onClick={handleShow}>
        Add Selling
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editingEntryId ? 'Edit Entry' : 'Add Selling'}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* Form Fields */}
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product name"
                value={formData.productName}
                onChange={(e) => setFormData({ ...formData, productName: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="productCategory">
              <Form.Label>Product Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product category"
                value={formData.productCategory}
                onChange={(e) => setFormData({ ...formData, productCategory: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="productBrand">
              <Form.Label>Product Brand</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product brand"
                value={formData.productBrand}
                onChange={(e) => setFormData({ ...formData, productBrand: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="productSize"> {/* Added productSize input */}
              <Form.Label>Product Size</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product size"
                value={formData.productSize}
                onChange={(e) => setFormData({ ...formData, productSize: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="productCost">
              <Form.Label>Product Cost</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter product cost"
                value={formData.productCost}
                onChange={(e) => setFormData({ ...formData, productCost: Number(e.target.value) })}
                required
              />
            </Form.Group>
            <Form.Group controlId="productSellingPrice">
              <Form.Label>Product Selling Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter product selling price"
                value={formData.productSellingPrice}
                onChange={(e) => setFormData({ ...formData, productSellingPrice: Number(e.target.value) })}
                required
              />
            </Form.Group>
            <Form.Group controlId="deliveryCharges">
              <Form.Label>Delivery Charges</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter delivery charges"
                value={formData.deliveryCharges}
                onChange={(e) => setFormData({ ...formData, deliveryCharges: Number(e.target.value) })}
                required
              />
            </Form.Group>
            <Form.Group controlId="miscellaneousCost">
              <Form.Label>Miscellaneous Cost</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter miscellaneous cost"
                value={formData.miscellaneousCost}
                onChange={(e) => setFormData({ ...formData, miscellaneousCost: Number(e.target.value) })}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {editingEntryId ? 'Save Changes' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {(isLoading || addLoading || deleteLoading || returnLoading || updateLoading) ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error?.data?.message || error.message || error.toString()}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm mt-4">
          <thead>
            <tr>
              <th>PRODUCT NAME</th>
              <th>CATEGORY</th>
              <th>BRAND</th>
              <th>SIZE</th> {/* Added product size column */}
              <th>COST</th>
              <th>SELLING PRICE</th>
              <th>DELIVERY</th>
              <th>MISC COST</th>
              <th>REVENUE</th>
              <th>PROFIT/LOSS</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ledgerEntries?.length > 0 ? (
              ledgerEntries.map((entry) => (
                <tr key={entry._id}>
                  <td>{entry.productName}</td>
                  <td>{entry.productCategory}</td>
                  <td>{entry.productBrand}</td>
                  <td>{entry.productSize}</td> {/* Display product size */}
                  <td>{entry.productCost} PKR</td>
                  <td>{entry.productSellingPrice === 0 ? 'Returned' : `${entry.productSellingPrice} PKR`}</td>
                  <td>{entry.deliveryCharges} PKR</td>
                  <td>{entry.miscellaneousCost} PKR</td>
                  <td>{entry.revenue} PKR</td>
                  <td>{entry.profitOrLoss}</td>
                  <td>{entry.status}</td>
                  <td>
                    <Button variant="danger" className="btn-sm" onClick={() => handleDelete(entry._id)}>
                      Delete
                    </Button>
                    <Button variant="secondary" className="btn-sm mx-2" onClick={() => handleEdit(entry)}>
                      Edit
                    </Button>
                    <Button variant="warning" className="btn-sm" onClick={() => handleReturn(entry._id)}>
                      R
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No entries found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default LedgerScreen;
