import React from 'react';
import zeeburLogo from '../assets/images/logos/zeebur-logo-wt.png';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="mt-20">
            {/* Top Section */}
            <div className="bg-black text-white py-2">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 py-4">
                    {/* Left Section */}
                    <div className="flex flex-col items-center md:items-center">
                        <img style={{ width: '80px', height: '80px' }} src={zeeburLogo} alt="Zeebur Logo" />
                        <span style={{ fontWeight: '500' }} >. The Brand Store .</span>
                    </div>

                    {/* Social Icons */}
                    <div className="flex space-x-6 text-2xl">
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#F26B3A]">
                            <FaInstagram />
                        </a>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#F26B3A]">
                            <FaFacebook />
                        </a>
                    </div>

                    {/* Right Section */}
                    <div className="text-white text-lg text-center md:text-right hover:text-[#F26B3A]">
                        <a href="#">Shop at ZEEBUR</a>
                    </div>
                </div>
            </div>

            {/* White Bottom Section */}
            <div className="bg-black text-white py-10">
                <div className="container mx-auto px-6">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                        {/* Press Section */}
                        <div className="text-left md:text-left">
                            <h3 className="text-lg text-black">PRESS</h3>
                            <a href="#" className="hover:text-[#F26B3A] flex items-center mt-3">
                            Get In Touch
                            </a>
                            <a href="#" className="hover:text-[#F26B3A] flex items-center mt-3">
                            +92 123 456 7890
                            </a>
                            <a href="#" className="hover:text-[#F26B3A] flex items-center mt-3">
                            contact.zeebur@gmail.com
                            </a>
                        </div>

                        {/* Contact Section */}
                        <div className="text-left md:text-center">
                            <h3 className="text-lg text-black">CONTACT</h3>
                            <div className="flex flex-col items-start md:items-center">
                                <a href="#" className="hover:text-[#F26B3A] flex items-center mt-3">
                                    Step up your game with Zeebur! We bring you 100% original shoes from top brands like Nike, Adidas, Sketchers, and more. Certified originals at prices that won't break the bank. Get your style on point with Zeebur!
                                </a>
                            </div>
                        </div>

                        {/* Zeebur Insights Section */}
                        <div className="text-left md:text-right">
                            <h3 className="text-lg text-black">ZEEBUR INSIGHTS</h3>
                            <div className="flex flex-col items-start md:items-end">
                                <a href="#" className="hover:text-[#F26B3A] flex items-center mt-3">
                                About ZEEBUR
                                </a>
                                <a href="#" className="hover:text-[#F26B3A] flex items-center mt-1">
                                Annual Report {currentYear}
                                </a>
                                <a href="#" className="hover:text-[#F26B3A] flex items-center mt-1">
                                ZEEBUR Blog
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Links */}
                    <div className="mt-10 border-t border-gray-200 pt-5 text-sm text-center">
                        <div className="flex justify-center flex-wrap space-x-4">
                            <a href="#" className="hover:text-[#F26B3A]">Privacy</a>
                            <a href="#" className="hover:text-[#F26B3A]">Cookies Settings</a>
                            <a href="#" className="hover:text-[#F26B3A]">Legal</a>
                        </div>
                        <p className="text-gray-500 mt-3">
                            All Rights Reserved &copy; ZEEBUR {currentYear}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
