// Parent to other API slices
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"; // Create API is used when we want to hit end points having async
// fetchBaseQuery is a function that allows us make request to the server/backend api
import { BASE_URL } from "../constants";

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['Product', 'Order', 'User'],
    endpoints: (builder) => ({}),
});