import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useGetTopProductsQuery } from "../slices/productsApiSlice";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import heroImage1 from "../assets/images/hero-section/hero-1.png";
import heroImage2 from "../assets/images/hero-section/hero-2.png";
import heroImage3 from "../assets/images/hero-section/hero-3.png";
import heroImage4 from "../assets/images/hero-section/hero-4.png";
import heroImage5 from "../assets/images/hero-section/hero-5.png";
import heroImage6 from "../assets/images/hero-section/hero-6.png";
import "../assets/styles/index.css";

// Carousel slides
const slides = [
  {
    id: 1,
    src: heroImage1,
    alt: "Fresh Kicks, Fresh Start",
    title: "FRESH KICKS, FRESH START",
    description: "Step into style with the latest designs",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
  {
    id: 2,
    src: heroImage2,
    alt: "100% Authentic Gear",
    title: "100% AUTHENTIC GEAR",
    description: "Experience unmatched quality with every step",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
  {
    id: 3,
    src: heroImage3,
    alt: "Leading Brands, Unbeatable Style",
    title: "LEADING BRANDS, UNBEATABLE STYLE",
    description: "Discover top-tier performance and elegance",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
  {
    id: 4,
    src: heroImage4,
    alt: "Stylish Comfort, All Day Long",
    title: "STYLISH COMFORT, ALL DAY LONG",
    description: "Stay comfortable without compromising on style",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
  {
    id: 5,
    src: heroImage5,
    alt: "Comfort Redefined",
    title: "COMFORT REDEFINED",
    description: "Embrace flexibility with every stride",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
  {
    id: 6,
    src: heroImage6,
    alt: "Unstoppable on Any Terrain",
    title: "UNSTOPPABLE ON ANY TERRAIN",
    description: "Tackle every trail with unmatched grip and resilience",
    buttonLabel: "Shop Shoes",
    buttonLink: "/products/all",
  },
];

const HomePage = () => {
  const { data: topProducts, error, isLoading } = useGetTopProductsQuery();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      {/* Product Slider */}
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id} className="relative">
            <img
              src={slide.src}
              alt={slide.alt}
              className="w-full h-[70vh] object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-40"></div>
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white">
              <h2 className="text-4xl md:text-6xl font-extrabold mb-4 leading-tight">
                {slide.title}
              </h2>
              <p className="text-lg mb-8">{slide.description}</p>
              <div className="text-center">
              <Link to={slide.buttonLink} className="animated-btn">
                {slide.buttonLabel}
              </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Top 8 Products */}
      <section className="my-10">
        <h2 className="text-3xl font-extrabold text-center mb-8 text-black">Top Products</h2>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">Error loading top products</Message>
        ) : (
          <div className="container mx-auto px-2 sm:px-8">
            {/* Grid layout */}
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {topProducts.map((product) => (
                <Product key={product._id} product={product} />
              ))}
            </div>
          </div>
        )}

      {/* Explore All Shoes Button */}
      <div className="text-center mt-8">
        <Link to="/products/all" className="animated-btn">
          <span>Explore All Shoes</span>
        </Link>
      </div>
      </section>
    </>
  );
};

// Custom next and previous arrows
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: 10, zIndex: 2 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 10, zIndex: 2 }}
      onClick={onClick}
    />
  );
};

export default HomePage;
