import { PRODUCTS_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ keyword, pageNumber, sizes, brand, available, category }) => ({
        url: `${PRODUCTS_URL}/all`,  // Updated to /all
        params: { 
          keyword, 
          pageNumber,
          sizes,     // New filter for size
          brand,     // New filter for brand
          available, // New filter for availability
          category   // New filter for category
        },
      }),
      keepUnusedDataFor: 5, // keep the data for 5 seconds
      providesTags: ["Products"], // gets rid of any cached products and shows the updated ones
    }),
    getSingleProduct: builder.query({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
      }),
      keepUnusedDataFor: 5, // keep the data for 5 seconds
    }),
    addNewProduct: builder.mutation({
      query: () => ({
        url: PRODUCTS_URL,
        method: "POST",
      }),
      invalidatesTags: ["Products"],
    }),
    editProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCTS_URL}/${data.productId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    uploadProductImage: builder.mutation({
      query: (data) => ({
        url: UPLOAD_URL,
        method: "POST",
        body: data,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
        method: "DELETE",
      }),
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${PRODUCTS_URL}/${data.productId}/reviews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    getTopProducts: builder.query({
      query: () => ({
        url: `${PRODUCTS_URL}/top`
      }),
      keepUnusedDataFor: 5, // keep the data for 5 seconds
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetSingleProductQuery,
  useAddNewProductMutation,
  useEditProductMutation,
  useUploadProductImageMutation,
  useDeleteProductMutation,
  useCreateReviewMutation,
  useGetTopProductsQuery,
} = productApiSlice;


// Query is used to get data
// Mutation is used to update, delete, add

// This slice will be used to fetch the products from frontend instead of useEffectHooke:
// Like this
// useEffect(() => {

//     const fetchProducts = async () => {
//       const { data } = await axios.get("/api/products");
//       setProducts(data);
//     }

//     fetchProducts();
//   }, [])

// This slice will be used to fetch the products from frontend instead of useEffectHooke, HTTPRequest or Axois
