import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Container } from 'react-bootstrap';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto'; // Disable any smooth scroll behavior
    window.scrollTo(0, 0)
    document.body.scrollTop = 0; // for Safari
  }, [location.pathname]); // Resets scroll on route change

  const isHomePage = location.pathname === '/';

  return (
    <>
      <Header />

      <main>
        {isHomePage ? (
          <Outlet />
        ) : (
          <Container>
            <Outlet />
          </Container>
        )}
      </main>

      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
