import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingAddress } from "../slices/cartSlice";
import { useNavigate } from "react-router-dom";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import "../assets/styles/index.css";

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);
  const { shippingAddress } = cart;

  // If user is logged in, use userInfo for name and email; otherwise, use shipping address or empty string
  const [name, setName] = useState(
    userInfo ? userInfo.name : shippingAddress.name || ""
  );
  const [email, setEmail] = useState(
    userInfo ? userInfo.email : shippingAddress.email || ""
  );
  const [address, setAddress] = useState(shippingAddress.address || "");
  const [city, setCity] = useState(shippingAddress.city || "");
  const [postalCode, setPostalCode] = useState(
    shippingAddress.postalCode || ""
  );
  const [country, setCountry] = useState(shippingAddress.country || "");
  const [phoneNumber, setPhoneNumber] = useState(shippingAddress.phoneNumber || ""); // New phone number state

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    // Dispatch the appropriate address based on whether the user is logged in or not
    dispatch(
      saveShippingAddress({
        name: userInfo ? userInfo.name : name,
        email: userInfo ? userInfo.email : email,
        address,
        city,
        postalCode,
        country,
        phoneNumber, // Include phone number
      })
    );
    navigate("/payment"); // Proceed to payment screen
  };

  return (
    <FormContainer>
      <CheckoutSteps step2 />
      <h1 className="text-3xl text-custom-orange font-semibold mb-4">
        Shipping
      </h1>

      {userInfo ? (
        <form onSubmit={submitHandler} className="text-md space-y-4">
          <div className="flex flex-col">
            <label
              htmlFor="address"
              className="text-custom-orange font-semibold"
            >
              Address
            </label>
            <input
              type="text"
              placeholder="Enter address"
              value={address}
              required
              name="address"
              id="address"
              onChange={(e) => setAddress(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* City */}
          <div className="flex flex-col">
            <label htmlFor="city" className="text-custom-orange font-semibold">
              City
            </label>
            <input
              type="text"
              placeholder="Enter city"
              value={city}
              required
              name="city"
              id="city"
              onChange={(e) => setCity(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Postal Code */}

          <div className="flex flex-col">
            <label
              htmlFor="postalCode"
              className="text-custom-orange font-semibold"
            >
              Postal Code
            </label>
            <input
              type="text"
              placeholder="Enter postal code"
              value={postalCode}
              required
              name="postalCode"
              id="postalCode"
              onChange={(e) => setPostalCode(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Country */}
          <div className="flex flex-col">
            <label
              htmlFor="country"
              className="text-custom-orange font-semibold"
            >
              Country
            </label>
            <input
              type="text"
              placeholder="Enter country"
              value={country}
              required
              name="country"
              id="country"
              onChange={(e) => setCountry(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="animated-btn"
            >
              Continue
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={submitHandler} className="text-md space-y-2">
          {/* Name */}
          <div className="flex flex-col">
            <label htmlFor="name" className="text-custom-orange font-semibold">
              Name
            </label>
            <input
              type="text"
              placeholder="Enter name"
              value={name}
              required
              name="text"
              id="text"
              onChange={(e) => setName(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Email */}
          <div className="flex flex-col">
            <label htmlFor="email" className="text-custom-orange font-semibold">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              required
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Phone Number */}
          <div className="flex flex-col">
            <label
              htmlFor="phoneNumber"
              className="text-custom-orange font-semibold"
            >
              Phone Number
            </label>
            <input
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              required
              name="phoneNumber"
              id="phoneNumber"
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Address */}
          <div className="flex flex-col">
            <label
              htmlFor="address"
              className="text-custom-orange font-semibold"
            >
              Address
            </label>
            <input
              type="text"
              placeholder="Enter address"
              value={address}
              required
              name="address"
              id="address"
              onChange={(e) => setAddress(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* City */}
          <div className="flex flex-col">
            <label htmlFor="city" className="text-custom-orange font-semibold">
              City
            </label>
            <input
              type="text"
              placeholder="Enter city"
              value={city}
              required
              name="city"
              id="city"
              onChange={(e) => setCity(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Postal Code */}

          <div className="flex flex-col">
            <label
              htmlFor="postalCode"
              className="text-custom-orange font-semibold"
            >
              Postal Code
            </label>
            <input
              type="text"
              placeholder="Enter postal code"
              value={postalCode}
              required
              name="postalCode"
              id="postalCode"
              onChange={(e) => setPostalCode(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Country */}
          <div className="flex flex-col">
            <label
              htmlFor="country"
              className="text-custom-orange font-semibold"
            >
              Country
            </label>
            <input
              type="text"
              placeholder="Enter country"
              value={country}
              required
              name="country"
              id="country"
              onChange={(e) => setCountry(e.target.value)}
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          <div className="flex justify-center items-center pt-4">
            <button
              type="submit"
              className="animated-btn"
            >
              Continue
            </button>
          </div>
        </form>
      )}
    </FormContainer>
  );
};

export default ShippingScreen;

{
  /* <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1 className="text-3xl text-custom-orange font-semibold mb-4">
        Shipping
      </h1>

      {userInfo ? (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="address" className="my-2">
            <Form.Label className="text-custom-orange font-semibold">
              Address
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city" className="my-2">
            <Form.Label className="text-custom-orange font-semibold">
              City
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="postalCode" className="my-2">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Postal Code"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="country" className="my-2">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="my-2">
            Continue
          </Button>
        </Form>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name" className="my-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email" className="my-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="address" className="my-2">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city" className="my-2">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="postalCode" className="my-2">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Postal Code"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="country" className="my-2">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="my-2">
            Continue
          </Button>
        </Form>
      )}
    </FormContainer> */
}
