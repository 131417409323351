import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apiSlice";
import cartSliceReducer from "./slices/cartSlice";
import authSliceReducer from "./slices/authSlice";
import { ledgerApiSlice } from "./slices/ledgerApiSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    cart: cartSliceReducer,
    auth: authSliceReducer,
    [ledgerApiSlice.reducerPath]: ledgerApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, ledgerApiSlice.middleware), devTools: true,
});

export default store;

// Slices are used to orangize the state, is a collection of reducer and actions related to each other
// Can create multiple slice can have its own state
// We will have root api slice and then extend it to other slices (product, order, users, etc)
