import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Table, Container } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "react-markdown-editor-lite/lib/index.css";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  useGetSingleProductQuery,
  useEditProductMutation,
  useUploadProductImageMutation,
} from "../../slices/productsApiSlice";
import {
  useGetSizeMappingsQuery,
  useGetAllCategoriesQuery,
  useGetAllBrandsForCategoryQuery,
} from "../../slices/mappingApiSlice";

const sortSizes = (sizes) => sizes.sort((a, b) => parseFloat(a.size) - parseFloat(b.size));

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [sizes, setSizes] = useState([]);

  const { data: singleProduct, isLoading: singleProductLoading, error: singleProductError } = useGetSingleProductQuery(productId);
  const { data: categories, isLoading: categoriesLoading, error: categoriesError } = useGetAllCategoriesQuery();
  const { data: brands, isLoading: brandsLoading, error: brandsError } = useGetAllBrandsForCategoryQuery(category, { skip: !category });
  const { data: sizeMappings, error: sizeMappingsError } = useGetSizeMappingsQuery({ category, brand }, { skip: !category || !brand });

  const [editProduct, { isLoading: loadingEdit }] = useEditProductMutation();
  const [uploadProductImage, { isLoading: loadingUpload }] = useUploadProductImageMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (singleProduct) {
      setName(singleProduct.name);
      setImages(singleProduct.images);
      setBrand(singleProduct.brand);
      setCategory(singleProduct.category);
      setDescription(singleProduct.description);
      setSizes(singleProduct.sizes || []);
    }
  }, [singleProduct]);

  useEffect(() => {
    if (sizeMappings) {
      let availableSizes = [];
      if (category === "Shoes" && sizeMappings["EU"]) {
        availableSizes = Object.keys(sizeMappings["EU"]).map((size) => ({
          size,
          available: false,
          countInStock: 0,
          price: 0,
          cost: 0,
          discountEnabled: false,
          discountPercentage: 0,
          discountedPrice: 0,
        }));
      } else if (["Shirts", "Pants"].includes(category) && sizeMappings["Sizes"]) {
        availableSizes = sizeMappings["Sizes"].map((size) => ({
          size,
          available: false,
          countInStock: 0,
          price: 0,
          cost: 0,
          discountEnabled: false,
          discountPercentage: 0,
          discountedPrice: 0,
        }));
      }
      const mergedSizes = availableSizes.map((availableSize) => {
        const existingSize = sizes.find((s) => s.size === availableSize.size);
        return existingSize || availableSize;
      });

      setSizes(sortSizes(mergedSizes));
    }
  }, [sizeMappings, category, sizes]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setBrand("");
    setSizes([]);
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
    setSizes([]);
  };

  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[index] = { ...updatedSizes[index], [field]: value };

    if (field === "discountPercentage" || field === "discountEnabled") {
      if (updatedSizes[index].discountEnabled) {
        updatedSizes[index].discountedPrice =
          updatedSizes[index].price - (updatedSizes[index].price * updatedSizes[index].discountPercentage / 100);
      } else {
        updatedSizes[index].discountedPrice = updatedSizes[index].price;
      }
    }
    setSizes(updatedSizes);
  };

  const productEditSubmitHandler = async (e) => {
    e.preventDefault();
    const newProduct = { productId, name, images, brand, category, description, sizes };
    try {
      await editProduct(newProduct).unwrap();
      toast.success("Product Updated Successfully");
      navigate("/admin/productlist");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImages([...images, ...res.images]);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const renderError = (error) => (
    <Message variant="danger">
      {error?.data?.message || error.message || "An error occurred"}
    </Message>
  );

  return (
    <>
      <Link to="/admin/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <Container>
        <h1>Edit Product</h1>
        {loadingEdit && <Loader />}
        {singleProductLoading ? (
          <Loader />
        ) : singleProductError ? (
          renderError(singleProductError)
        ) : categoriesError ? (
          renderError(categoriesError)
        ) : (
          <Form onSubmit={productEditSubmitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="category" className="my-2">
              <Form.Label>Product Category</Form.Label>
              {categoriesLoading ? (
                <Loader />
              ) : (
                <Form.Control
                  as="select"
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  {categories && categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>

            {category && (
              <Form.Group controlId="brand" className="my-2">
                <Form.Label>Product Brand</Form.Label>
                {brandsLoading ? (
                  <Loader />
                ) : (
                  <Form.Control
                    as="select"
                    value={brand}
                    onChange={handleBrandChange}
                    disabled={!category}
                  >
                    <option value="">Select Brand</option>
                    {brands && brands.map((brand) => (
                      <option key={brand} value={brand}>
                        {brand}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </Form.Group>
            )}

            <Form.Group controlId="images" className="my-2">
              <Form.Label>Product Images</Form.Label>
              <Form.Control
                type="file"
                label="Choose Files"
                multiple
                onChange={uploadFileHandler}
              />
              <div>
                {images.map((img, index) => (
                  <img key={index} src={img} alt={`Product ${index}`} width="50" />
                ))}
              </div>
            </Form.Group>

            {loadingUpload && <Loader />}

            {sizes && sizes.length > 0 && (
              <Form.Group controlId="sizes" className="my-2">
                <Container fluid className="px-0">
                  <Table responsive bordered hover className="mb-0 w-100">
                    <thead>
                      <tr>
                        <th className="size-column">Size</th>
                        <th>Stock Count</th>
                        <th>Selling Price</th>
                        <th>Discount %</th>
                        <th>Discounted Price</th>
                        <th>Cost Price</th>
                        <th>Available</th>
                        <th>Enable Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sizes.map((size, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control type="text" value={size.size} readOnly className="form-control-sm" />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={size.countInStock}
                              onChange={(e) => handleSizeChange(index, "countInStock", e.target.value)}
                              placeholder="Stock Count"
                              className="form-control-sm"
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={size.price}
                              onChange={(e) => handleSizeChange(index, "price", e.target.value)}
                              placeholder="Selling Price"
                              className="form-control-sm"
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={size.discountPercentage}
                              onChange={(e) => handleSizeChange(index, "discountPercentage", e.target.value)}
                              placeholder="Discount %"
                              disabled={!size.discountEnabled}
                              className="form-control-sm"
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={size.discountedPrice}
                              readOnly
                              placeholder="Discounted Price"
                              className="form-control-sm"
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={size.cost}
                              onChange={(e) => handleSizeChange(index, "cost", e.target.value)}
                              placeholder="Cost Price"
                              className="form-control-sm"
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={size.available}
                              onChange={(e) => handleSizeChange(index, "available", e.target.checked)}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={size.discountEnabled}
                              onChange={(e) => handleSizeChange(index, "discountEnabled", e.target.checked)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Container>
              </Form.Group>
            )}

<Form.Group controlId="description" className="my-2">
  <Form.Label>Product Description</Form.Label>
  <CKEditor
  editor={ClassicEditor}
  data={description}
  onChange={(event, editor) => {
    const data = editor.getData();
    setDescription(data); // Save the description in HTML format
  }}
  config={{
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      ],
    },
    autoParagraph: true, // Automatically wraps inline text in paragraphs
    enterMode: 1, // Enter key creates a new paragraph
    shiftEnterMode: 2, // Shift+Enter creates a line break
  }}
/>

</Form.Group>

            <Button type="submit" variant="primary" className="my-2">
              Edit Product
            </Button>
          </Form>
        )}
      </Container>
    </>
  );
};

export default ProductEditScreen;
