import React from "react";
import { Form, Row, Col, Button, OverlayTrigger, Tooltip, Nav } from "react-bootstrap";

// Helper function to sort sizes numerically, accounting for floating-point numbers
const sortSizes = (sizes) => {
  return sizes.sort((a, b) => parseFloat(a.mappedSize) - parseFloat(b.mappedSize));
};

const SizeSelector = ({
  sizes,
  selectedSize,
  setSelectedSize,
  sizeMappings,
  sizeType,
  setSizeType,
  category, // Pass the category as a prop
}) => {
  const handleSizeTypeChange = (type) => {
    setSizeType(type);
  };

  // Function to get mapped sizes for shoes
  const getMappedSizesForShoes = () => {
    return sizes.map((sizeObj) => {
      const mappingForSize = sizeMappings?.EU?.[sizeObj.size];
      const mappedSize = mappingForSize ? mappingForSize[sizeType] : sizeObj.size;
      return {
        ...sizeObj,
        mappedSize: mappedSize || sizeObj.size,
      };
    });
  };

  // For shirts, return the sizes as they are
  const getSizesForShirts = () => {
    return sizes.map((sizeObj) => ({
      ...sizeObj,
      mappedSize: sizeObj.size, // Just return the size directly without any mapping
    }));
  };

  // Get the mapped sizes based on category and sort them
  const mappedSizes = category === "Shoes" ? getMappedSizesForShoes() : getSizesForShirts();

  // Sort the sizes in ascending order
  const sortedSizes = sortSizes(mappedSizes);

  return (
    <>
      <Form.Group controlId="sizeSelector" className="my-3">
        <Form.Label className="text-black">
          Select Size {category === "Shoes" && `- ${sizeType}`}
        </Form.Label>

        {/* Size type selector for shoes */}
        {category === "Shoes" && (
          <Nav variant="tabs" activeKey={sizeType} onSelect={handleSizeTypeChange} className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="EU">EU</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="UK">UK</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="US">US</Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        <Row>
          {sortedSizes.map((sizeObj, index) => {
            const isOutOfStock = sizeObj.countInStock <= 0;
            const isDisabled = isOutOfStock || !sizeObj.available;

            return (
              <Col key={index} xs={6} md={4} lg={3} className="mb-2">
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-${sizeObj.size}`}>
                      {isDisabled ? "Unavailable" : `Size: ${sizeObj.mappedSize}`}
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      variant="light"
                      disabled={isDisabled}
                      className={`w-100 ${isDisabled ? "disabled-size" : ""}`}
                      onClick={() => !isDisabled && setSelectedSize(sizeObj.size)}
                      style={{
                        border:
                          selectedSize === sizeObj.size
                            ? "2px solid #F16E36"
                            : "1px solid #a9a9a9",
                        backgroundColor: isDisabled ? "#e0e0e0" : "#ffffff",
                        color: isDisabled ? "#a9a9a9" : "#000000",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        boxShadow:
                          selectedSize === sizeObj.size
                            ? "0 0 10px rgba(241, 110, 54, 0.5)"
                            : "none",
                        fontWeight: selectedSize === sizeObj.size ? "bold" : "normal",
                      }}
                    >
                      {sizeObj.mappedSize}
                    </Button>
                  </div>
                </OverlayTrigger>
              </Col>
            );
          })}
        </Row>
      </Form.Group>
    </>
  );
};

export default SizeSelector;
