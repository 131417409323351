import { apiSlice } from "./apiSlice";

export const guestSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGuestToken: builder.query({
      query: () => ({
        url: "/api/guest/token",
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetGuestTokenQuery,
} = guestSlice;
