import { apiSlice } from './apiSlice';

export const mappingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get size mappings based on category and brand
    getSizeMappings: builder.query({
      query: ({ category, brand }) => `/api/mappings/sizes/${category}/${brand}`,
    }),
    
    // Fetch all available categories (Shoes, Shirts, etc.)
    getAllCategories: builder.query({
      query: () => '/api/mappings/categories',
    }),

    // Fetch all brands for a specific category
    getAllBrandsForCategory: builder.query({
      query: (category) => `/api/mappings/brands/${category}`,
    }),
  }),
});

export const { useGetSizeMappingsQuery, useGetAllCategoriesQuery, useGetAllBrandsForCategoryQuery } = mappingApiSlice;
