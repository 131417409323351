import { Navbar, Nav, Container, Badge } from "react-bootstrap";
import { FaShoppingCart, FaBars, FaTimes } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import zeeburLogo from "../assets/images/logos/zeebur-logo.png";
import jordanLogo from "../assets/images/logos/jordan-logo.png";
import asicsLogo from "../assets/images/logos/asics-logo.png";
import newBalanceLogo from "../assets/images/logos/nb-logo.png";
import nikeLogo from "../assets/images/logos/nike-logo.png";
import pumaLogo from "../assets/images/logos/puma-logo.png";
import reebokLogo from "../assets/images/logos/reebok-logo.png";
import skechersLogo from "../assets/images/logos/skechers-logo.png";
import underArmourLogo from "../assets/images/logos/ua-logo.png";

import SearchBox from "./SearchBox";

const Header = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logoutApiCall] = useLogoutMutation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const adminDropdownRef = useRef(null);

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/");
      toast.success("You are now logged out!");
      closeDropdowns(); // Close dropdowns upon logout
    } catch (error) {
      console.log(error);
    }
  };

  const loginHandler = () => {
    closeMenu();
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (adminDropdownOpen) {
      setAdminDropdownOpen(false); // Close admin dropdown if open
    }
  };

  const toggleAdminDropdown = () => {
    setAdminDropdownOpen(!adminDropdownOpen);
    if (dropdownOpen) {
      setDropdownOpen(false); // Close user dropdown if open
    }
  };

  const closeDropdowns = () => {
    setDropdownOpen(false);
    setAdminDropdownOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth >= 768 && mobileMenuOpen) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.body.classList.toggle('overflow-hidden', mobileMenuOpen);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.classList.remove('overflow-hidden');
    };
  }, [mobileMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (adminDropdownRef.current && !adminDropdownRef.current.contains(event.target)) {
        setAdminDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header>
      {/* Mini Header (visible on large screens) */}
      <div className="mini-header bg-light py-1 px-3 hidden md:flex">
        <Container className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <img className="w-7 h-8" src={jordanLogo} alt="Jordan Logo" />
            <img className="w-14 h-4" src={asicsLogo} alt="Asics Logo" />
            <img className="w-14 h-8" src={newBalanceLogo} alt="New Balance Logo" />
            <img className="w-12 h-8" src={nikeLogo} alt="Nike Logo" />
            <img className="w-10 h-6" src={pumaLogo} alt="Puma Logo" />
            <img className="w-10 h-6" src={reebokLogo} alt="Reebok Logo" />
            <img className="w-18 h-10" src={skechersLogo} alt="Skechers Logo" />
            <img className="w-10 h-6" src={underArmourLogo} alt="Under Armour Logo" />
          </div>
          <div className="text-right">
            {!userInfo && (
              <span onClick={loginHandler} className="cursor-pointer text-black">
                Sign In
              </span>
            )}
          </div>
        </Container>
      </div>

      {/* Main Header */}
      <Navbar expand="md" collapseOnSelect className="relative py-2">
        <Container className="flex justify-between items-center">
          <LinkContainer to="/">
            <Navbar.Brand className="flex flex-row items-center gap-2">
              <img className="w-10 h-12" src={zeeburLogo} alt="Zeebur Logo" />
            </Navbar.Brand>
          </LinkContainer>

          <div className="flex items-center gap-3">
            <div className="hidden md:flex">
              <SearchBox closeMenu={closeMenu} />
            </div>
            <LinkContainer to="/cart">
              <Nav.Link className="hover:text-custom-orange relative text-black">
                <FaShoppingCart />
                {cartItems.length > 0 && (
                  <Badge
                    pill
                    bg="success"
                    className="absolute top-0 start-100 translate-middle rounded-full bg-danger"
                  >
                    {cartItems.reduce((acc, currentItem) => acc + currentItem.quantity, 0)}
                  </Badge>
                )}
              </Nav.Link>
            </LinkContainer>

            {userInfo && (
              <div ref={dropdownRef} className="relative">
                <button 
                  onClick={toggleDropdown} 
                  className="text-lg text-black hover:text-custom-orange flex items-center"
                >
                  {userInfo.name}
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                    <LinkContainer to="/profile">
                      <button 
                        onClick={closeDropdowns} 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                      >
                        Profile
                      </button>
                    </LinkContainer>
                    <button
                      onClick={logoutHandler}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            )}

            {userInfo && userInfo.isAdmin && (
              <div ref={adminDropdownRef} className="relative">
                <button 
                  onClick={toggleAdminDropdown} 
                  className="text-lg text-black hover:text-custom-orange flex items-center"
                >
                  Admin
                </button>
                {adminDropdownOpen && (
                  <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                    <LinkContainer to="admin/productlist">
                      <button 
                        onClick={closeDropdowns} 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                      >
                        Products
                      </button>
                    </LinkContainer>
                    <LinkContainer to="admin/orderlist">
                      <button 
                        onClick={closeDropdowns} 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                      >
                        Orders
                      </button>
                    </LinkContainer>
                    <LinkContainer to="admin/userlist">
                      <button 
                        onClick={closeDropdowns} 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                      >
                        Users
                      </button>
                    </LinkContainer>
                    <LinkContainer to="admin/ledger">
                      <button 
                        onClick={closeDropdowns} 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                      >
                        Ledger
                      </button>
                    </LinkContainer>
                  </div>
                )}
              </div>
            )}
            <button className="md:hidden text-xl text-black" onClick={toggleMobileMenu}>
              {mobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Mobile Slide-in Menu */}
          {mobileMenuOpen && <div onClick={closeMenu} className="fixed inset-0 bg-black opacity-50 z-40"></div>}
          <div
            className={`fixed top-0 right-0 h-full bg-white z-50 p-6 transform transition-transform duration-300 ${
              mobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } w-4/5 sm:w-3/4`}
          >
            <div className="flex justify-between items-center mb-8 mt-2">
              <h1 className="text-black text-center font-bold capitalize w-full">. OFFERING TOP ICONS .</h1>
              <button onClick={toggleMobileMenu} className="text-xl text-black">
                <FaTimes />
              </button>
            </div>

            {/* Search Box */}
            <div className="w-full mt-10">
              <SearchBox closeMenu={closeMenu} />
            </div>

            <div className="flex flex-col items-center space-y-4 mt-14">
              {/* Grid style for mini logos */}
              <div className="grid grid-cols-3 gap-4 justify-center">
                <img className="w-12 h-13 mx-auto" src={jordanLogo} alt="Jordan Logo" />
                <img className="w-15 h-6 mx-auto" src={asicsLogo} alt="Asics Logo" />
                <img className="w-13 h-10 mx-auto" src={underArmourLogo} alt="Under Armour Logo" />
                <img className="w-14 h-10 mx-auto" src={nikeLogo} alt="Nike Logo" />
                <img className="w-14 h-10 mx-auto" src={pumaLogo} alt="Puma Logo" />
                <img className="w-14 h-10 mx-auto" src={reebokLogo} alt="Reebok Logo" />
                <img className="w-18 h-12 mx-auto" src={newBalanceLogo} alt="New Balance Logo" />
                <img className="w-18 h-12 mx-auto" src={skechersLogo} alt="Skechers Logo" />
              </div>

              <div className="mt-4"></div>

              {/* Sign In/Logout button */}
              {userInfo ? (
                <button
                  onClick={logoutHandler}
                  className="mt-8 px-20 py-2 rounded-full bg-black text-white"
                >
                  Logout
                </button>
              ) : (
                <button
                  onClick={loginHandler}
                  className="mt-8 px-20 py-2 rounded-full bg-black text-white"
                >
                  Sign In
                </button>
              )}
            </div>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
