import { Link } from "react-router-dom";
import Rating from "./Rating";
import React from "react";
import "../assets/styles/product-card.css"; // Import the new product card styles

function Product({ product }) {
  const getProductPriceDisplay = () => {
    // Filter sizes that are available and have stock
    const availableSizes = product.sizes.filter(size => size.available && size.countInStock > 0);

    if (availableSizes.length === 0) {
      return "Unavailable";
    }

    // Initialize variables to track the size with the maximum discount percentage
    let maxDiscountedPrice = 0;
    let maxOriginalPrice = 0;
    let maxDiscountPercentage = 0;
    let isDiscountApplied = false;

    // Loop through available sizes to find the one with the maximum discount percentage
    availableSizes.forEach(size => {
      const { price, discountEnabled, discountPercentage, discountedPrice } = size;

      // Check if discount is enabled and track the size with the maximum discount percentage
      if (discountEnabled && discountPercentage > maxDiscountPercentage) {
        isDiscountApplied = true;
        maxDiscountPercentage = discountPercentage;
        maxDiscountedPrice = discountedPrice;
        maxOriginalPrice = price;
      } else if (!isDiscountApplied) {
        // If no discount is applied, track the minimum original price as a fallback
        maxOriginalPrice = Math.min(maxOriginalPrice || Number.MAX_VALUE, price);
      }
    });

    // Return the formatted display based on whether a discount is applied
    if (isDiscountApplied) {
      return (
        <div>
          <span className="discounted-price">{maxDiscountedPrice} PKR</span>
          <span className="original-price">
            <s>{maxOriginalPrice} PKR</s>
          </span>
          <span className="discount-percentage">{maxDiscountPercentage}% off</span>
        </div>
      );
    } else {
      // If no discount is applied, show only the original price
      return <span className="single-price">{maxOriginalPrice} PKR</span>;
    }
  };

  return (
    <Link
      to={`/product/${product._id}`}
      className="product-card hover:cursor-pointer transition-all"
    >
      <div className="product-image-container">
        <img
          src={product.images[1] || product.images[0]}
          alt={product.name || "Not available!!!"}
          className="product-image"
        />
      </div>
      <div className="product-info text-black pt-2">
        <span className="text-xs text-custom-orange font-semibold">Just In</span>
        <h4 className="mt-1 font-semibold text-base hover:text-custom-orange pt-1">{product.name}</h4>
        <p className="text-sm text-gray-600 pt-1">Sports Shoes</p>
        <Rating ratingValue={product.rating} ratingPosition="justify-start" />
        <div className="price mt-2">{getProductPriceDisplay()}</div>
      </div>
    </Link>
  );
}

export default Product;
