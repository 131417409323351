import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.auth); // get user info from the auth
  const { search } = useLocation();
  const seachParams = new URLSearchParams(search);
  const redirect = seachParams.get("redirect") || "/"; // nativage to either index page or whatever is in the index such as shipping

  // check to see if we are already logged in
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // lets call the login mutation
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res })); // set the credentials in the auth slice
      toast.success("You are now logged in!");
      navigate(redirect); // navigate to the redirect page
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className=" w-full max-w-xl mx-auto mt-[2rem] p-2">
      <h1 className="text-3xl text-center text-custom-orange font-semibold mb-4">
        Sign In
      </h1>
      <form onSubmit={submitHandler} className="text-lg space-y-4">
        {/* Email */}
        <div className="flex flex-col">
          <label htmlFor="email" className="text-custom-orange font-semibold">
            Email
          </label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        {/* Password */}
        <div className="flex flex-col">
          <label
            htmlFor="password"
            className="text-custom-orange font-semibold"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
          />
        </div>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="mt-2 px-3 py-[4px] rounded-md bg-custom-orange text-white hover:cursor-pointer hover:scale-105 transition-all"
            disabled={isLoading}
          >
            Sign In
          </button>
        </div>
        <p className="flex justify-center items-center text-black">
          New Customer?{" "}
          <Link
            className="text-custom-orange font-semibold ml-2"
            to={redirect ? `/register?redirect=${redirect}` : "/register"}
          >
            Register
          </Link>
        </p>
        {isLoading && <Loader />}
      </form>
    </div>
  );
};

export default LoginScreen;

{
  /* <h1>Sign In</h1>

<Form onSubmit={submitHandler}>
  <Form.Group controlId="email" className="my-3">
    <Form.Label>Email Address</Form.Label>
    <Form.Control
      type="email"
      placeholder="Enter email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    ></Form.Control>
  </Form.Group>

  <Form.Group controlId="password" className="my-3">
    <Form.Label>Password</Form.Label>
    <Form.Control
      type="password"
      placeholder="Enter Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    ></Form.Control>
  </Form.Group>

  <Button
    type="submit"
    variant="primary"
    className="mt-2"
    disabled={isLoading}
  >
    Sign In
  </Button>

  When we try to login
  {isLoading && <Loader />}
</Form>

<Row className="py-3">
  <Col>
    New Customer?{" "}
    <Link
      to={redirect ? `/register?redirect=${redirect}` : "/register"}
    >
      Register
    </Link>
  </Col>
</Row> */
}
