import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

function Rating({ ratingValue, textAlongSide, ratingPosition }) {
  return (
    <div className={`rating flex ${ratingPosition} my-2`}>
      <span>
        {ratingValue >= 1 ? (
          <FaStar className="text-custom-orange" />
        ) : ratingValue >= 0.5 ? (
          <FaStarHalfAlt className="text-custom-orange" />
        ) : (
          <FaRegStar className="text-custom-orange" />
        )}
      </span>

      <span>
        {ratingValue >= 2 ? (
          <FaStar className="text-custom-orange" />
        ) : ratingValue >= 1.5 ? (
          <FaStarHalfAlt className="text-custom-orange" />
        ) : (
          <FaRegStar className="text-custom-orange" />
        )}
      </span>

      <span>
        {ratingValue >= 3 ? (
          <FaStar className="text-custom-orange" />
        ) : ratingValue >= 2.5 ? (
          <FaStarHalfAlt className="text-custom-orange" />
        ) : (
          <FaRegStar className="text-custom-orange" />
        )}
      </span>

      <span>
        {ratingValue >= 4 ? (
          <FaStar className="text-custom-orange" />
        ) : ratingValue >= 3.5 ? (
          <FaStarHalfAlt className="text-custom-orange" />
        ) : (
          <FaRegStar className="text-custom-orange" />
        )}
      </span>

      <span>
        {ratingValue >= 5 ? (
          <FaStar className="text-custom-orange" />
        ) : ratingValue >= 4.5 ? (
          <FaStarHalfAlt className="text-custom-orange" />
        ) : (
          <FaRegStar className="text-custom-orange" />
        )}
      </span>

      {/* If text is available, display it */}
      <span className="textAlongSide">{textAlongSide && textAlongSide}</span>
    </div>
  );
}

export default Rating;
