import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Table, FormGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useProfileMutation } from "../slices/usersApiSlice";
import { useGetMyOrdersQuery } from "../slices/ordersApiSlice";
import { setCredentials } from "../slices/authSlice";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useProfileMutation();

  const { data: orders, isLoading, error } = useGetMyOrdersQuery();

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
    } else {
      navigate("/");  // Redirect to home page if userInfo is null
    }
  }, [userInfo, navigate]);

  const profileUpdateSubmitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
        }).unwrap();

        dispatch(setCredentials(res));
        toast.success("Profile Updated Successfully");
      } catch (error) {
        toast.error(error?.data?.message || error?.error);
      }
    }
  };

  if (!userInfo) {
    return null; // Prevent the component from rendering if userInfo is null
  }

  return (
    <Row>
      <Col md={3}>
        <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
          Your Profile
        </h2>
        <form onSubmit={profileUpdateSubmitHandler}>
          {/* Name */}
          <div className="flex flex-col">
            <label htmlFor="name" className="text-custom-orange font-semibold">
              Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          {/* Email */}
          <div className="flex flex-col">
            <label htmlFor="email" className="text-custom-orange font-semibold">
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>
          {/* Password */}
          <div className="flex flex-col">
            <label
              htmlFor="password"
              className="text-custom-orange font-semibold"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>
          {/* Confirm Password */}
          <div className="flex flex-col">
            <label
              htmlFor="confirmPassword"
              className="text-custom-orange font-semibold"
            >
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="pl-2 py-[3px] border-2 border-gray-100 rounded-md placeholder:text-black outline-border-custom-orange outline-custom-orange"
            />
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="mt-2 px-3 py-[4px] rounded-md bg-custom-orange text-white hover:cursor-pointer hover:scale-105 transition-all"
              disabled={isLoading}
            >
              Sign Up
            </button>
          </div>
          {loadingUpdateProfile && <Loader />}
        </form>
      </Col>
    </Row>
  );
};

export default ProfileScreen;
