import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Zoom from "react-medium-image-zoom";
import {
  useGetSingleProductQuery,
  useCreateReviewMutation,
} from "../slices/productsApiSlice";
import { useGetGuestTokenQuery } from "../slices/guestSlice";
import { useGetSizeMappingsQuery } from "../slices/mappingApiSlice";
import { Row, Col, ListGroup, Button, Form } from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { addToCart } from "../slices/cartSlice";
import { toast } from "react-toastify";
import Meta from "../components/Meta";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-medium-image-zoom/dist/styles.css";
import SizeSelector from "../components/SizeSelector";
import "../assets/styles/index.css";
import parse from 'html-react-parser';


const ProductScreen = () => {
  const { id: productId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeMappings, setSizeMappings] = useState(null);
  const [sizeType, setSizeType] = useState("EU");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: guestUserToken } = useGetGuestTokenQuery();
  const guestToken = guestUserToken && guestUserToken.guestToken;

  const { data: product, error, isLoading, refetch } = useGetSingleProductQuery(productId);

  const { data: fetchedSizeMappings } = useGetSizeMappingsQuery(
    { category: product?.category, brand: product?.brand },
    { skip: !product?.category || !product?.brand }
  );

  useEffect(() => {
    if (fetchedSizeMappings) {
      setSizeMappings(fetchedSizeMappings);
    }
  }, [fetchedSizeMappings]);

  const addToCartHandler = () => {
    if (selectedSize) {
      const sizeObj = product.sizes.find(
        (sizeObj) => sizeObj.size === selectedSize
      );
      if (sizeObj) {
        if (quantity <= sizeObj.countInStock) {
          // Use discounted price if discount is enabled, otherwise use the original price
          const finalPrice = sizeObj.discountEnabled
            ? sizeObj.discountedPrice
            : sizeObj.price;
  
          dispatch(
            addToCart({
              ...product,
              quantity: Number(quantity),
              size: selectedSize,
              sizeType,
              price: finalPrice, // Use the calculated final price
              countInStock: sizeObj.countInStock,
              sizeMapping: sizeMappings,
            })
          );
          navigate("/cart");
        } else {
          toast.error("Selected quantity exceeds available stock for this size.");
        }
      } else {
        toast.error("Size not found.");
      }
    } else {
      toast.error("Please select a size before adding to cart.");
    }
  };
  

  const [createReview, { isLoading: loadingProductReview }] =
    useCreateReviewMutation();

  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    const reviewData = {
      productId,
      rating,
      comment,
      ...(userInfo ? {} : { name }),
    };
    try {
      await createReview(reviewData).unwrap();
      refetch();
      toast.success("Review Submitted Successfully!");
      setRating(0);
      setComment("");
      if (!userInfo) setName("");
    } catch (err) {
      toast.error(err?.data?.message || err.message || "An error occurred");
    }
  };

  const Arrow = ({ className, style, onClick, direction }) => (
    <div
      className={className}
      style={{ ...style, display: "block", [direction]: "10px" }}
      onClick={onClick}
    />
  );

  const settings = {
    dots: product?.images.length > 1,
    infinite: product?.images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
  };

  const filteredImages = product
    ? product.images.filter((img) => !img.includes("sample"))
    : [];

  const renderPrice = () => {
    if (!selectedSize) {
      return "Select size first to see the price";
    }

    const sizeObj = product.sizes.find((size) => size.size === selectedSize);

    if (sizeObj && sizeObj.discountEnabled) {
      return (
        <div>
          <span className="discounted-price">{sizeObj.discountedPrice} PKR</span>
          <span className="original-price">
            <s>{sizeObj.price} PKR</s>
          </span>
          <span className="discount-percentage">
            {sizeObj.discountPercentage}% off
          </span>
        </div>
      );
    } else if (sizeObj) {
      return <span className="single-price">{sizeObj.price} PKR</span>;
    }

    return "Price not available";
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <span onClick={goBackHandler} className="ml-2">
        <button className="animated-btn">
          Go Back
        </button>
      </span>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title={product.name} />
          <div className="product-page mt-4">
            <div className="product-details">
              <div className="product-images">
                {filteredImages.length > 1 ? (
                  <Slider {...settings}>
                    {filteredImages.map((image, index) => (
                      <div key={index}>
                        <Zoom>
                          <img
                            src={image}
                            alt={product.name}
                            className="img-fluid"
                            style={{ cursor: "pointer" }}
                          />
                        </Zoom>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <Zoom>
                    <img
                      src={filteredImages[0]}
                      alt={product.name}
                      className="img-fluid"
                      style={{ cursor: "pointer" }}
                    />
                  </Zoom>
                )}
              </div>
              <div className="product-info flex flex-col justify-start">
                <h3 className="mb-2 text-black text-2xl font-semibold product-name">
                  {product.name}
                </h3>
                <Rating
                  ratingValue={product.rating}
                  textAlongSide={`${product.numReviews} reviews`}
                  ratingPosition="justify-start"
                />

                <div className="price text-custom-orange text-2xl mb-8">
                  {renderPrice()}
                </div>
                <hr />

                <div className="my-2 text-black product-description-container">
  {parse(product.description)} {/* Converts HTML to React elements */}
</div>

                <SizeSelector
                  sizes={product.sizes}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                  sizeMappings={sizeMappings}
                  sizeType={sizeType}
                  setSizeType={setSizeType}
                  category={product.category}
                />

                {selectedSize &&
                  product.sizes.find((sizeObj) => sizeObj.size === selectedSize)
                    .countInStock > 0 && (
                    <>
                      <ListGroup className="my-3" style={{ width: "100%" }}>
                        <ListGroup.Item>
                          <Row className="text-black font-bold">
                            <Col>Status:</Col>
                            <Col>
                              <strong>
                                {product.sizes.find(
                                  (sizeObj) => sizeObj.size === selectedSize
                                ).countInStock > 0
                                  ? "In Stock"
                                  : "Out of Stock"}
                              </strong>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row className="text-black font-bold">
                            <Col>Quantity</Col>
                            <Col>
                              <Form.Control
                                as="select"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                className="text-black"
                              >
                                {[
                                  ...Array(
                                    product.sizes.find(
                                      (sizeObj) => sizeObj.size === selectedSize
                                    ).countInStock
                                  ).keys(),
                                ].map((x) => (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>

                      <ListGroup className="my-3" style={{ width: "100%" }}>
                        <ListGroup.Item>
                          <button
                            className="animated-btn"
                            type="button"
                            disabled={
                              !selectedSize ||
                              product.sizes.find(
                                (sizeObj) => sizeObj.size === selectedSize
                              ).countInStock === 0
                            }
                            onClick={addToCartHandler}
                            style={{ width: "100%" }}
                          >
                            <span style={{ width: "100%", fontWeight: "600" }}>Add to Cart</span>
                          </button>
                        </ListGroup.Item>
                      </ListGroup>
                    </>
                  )}
              </div>
            </div>
          </div>

          <Row className="review py-4">
            <Col md={6}>
              <h2 className="text-black bg-transparent">Customer Reviews</h2>
              <br></br>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}

              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating ratingValue={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2 className="text-black bg-transparent">Write a review</h2>
                  {loadingProductReview && <Loader />}
                  {userInfo ? (
                    <Form onSubmit={reviewSubmitHandler}>
                      <Form.Group controlId="rating" className="my-2">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(Number(e.target.value))}
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment" className="my-2">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        className="animated-btn"
                        disabled={loadingProductReview}
                        type="submit"
                        variant="primary"
                      >
                        Submit Review
                      </Button>
                    </Form>
                  ) : (
                    <Form onSubmit={reviewSubmitHandler}>
                      <Form.Group controlId="name" className="my-2">
                        <Form.Label className="text-black">Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                          className="placeholder:text-black border-black"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="rating" className="my-2">
                        <Form.Label className="text-black">Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(Number(e.target.value))}
                          className="placeholder:text-black border-black"
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment" className="my-2">
                        <Form.Label className="text-black">Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          className="border-black text-black"
                        ></Form.Control>
                      </Form.Group>
                      <button
                        disabled={loadingProductReview}
                        type="submit"
                        className="animated-btn"
                      >
                        Submit Review
                      </button>
                    </Form>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
