import { Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  useGetOrderDetailsQuery,
  useDeliverOrderMutation,
  usePayOrderMutation,
} from "../slices/ordersApiSlice";
import { toast } from "react-toastify";
import { useGetGuestTokenQuery } from "../slices/guestSlice";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../assets/styles/index.css";

const OrderScreen = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const redirectToHome = () => {
    navigate("/");
  };

  const { id: orderId } = useParams();

  const { data: guestUserToken } = useGetGuestTokenQuery();
  const guestToken = guestUserToken && guestUserToken.guestToken;
  // console.log(`Order Screen Guest Token: ${guestToken}`);

  const {
    data: order,
    error,
    isLoading,
    refetch,
  } = useGetOrderDetailsQuery({
    orderId,
    token: userInfo ? userInfo.token : guestToken,
  });

  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation();
  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();

  const deliverOrderHandler = async () => {
    try {
      await deliverOrder(orderId).unwrap();
      refetch();
      toast.success("Order Delivered Successfully");
    } catch (error) {
      toast.error(
        error?.data?.message || error?.message || "Failed to mark as delivered"
      );
    }
  };

  const payOrderHandler = async () => {
    try {
      await payOrder(orderId).unwrap();
      refetch();
      toast.success("Order Paid Successfully");
    } catch (error) {
      toast.error(
        error?.data?.message || error?.message || "Failed to mark as Paid"
      );
    }
  };

  const processErrorMessage = (error) => {
    return (
      error?.data?.message || error?.message || JSON.stringify(error, null, 2)
    );
  };

  if (isLoading) return <Loader />;
  if (error)
    return <Message variant="danger">{processErrorMessage(error)}</Message>;

  const downloadReceiptHandler = () => {
    const input = document.getElementById("order-receipt");
    const OrderWebURL = "https://zeebur.com/order/";

    html2canvas(input, { scale: 2, backgroundColor: "#ffffff" })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // Adding the clickable link
        pdf.textWithLink(`Click to see delivery status: ${OrderWebURL}${order._id}`, 1, position + 240, { url: `${OrderWebURL}${order._id}` });

        pdf.save(`Order_${order._id}_Receipt.pdf`);
      })
      .catch((error) => {
        console.error("Could not generate receipt:", error);
      });
  };

  return (
    <div id="order-receipt">
      <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
        We have received your order, our team will contact you shortly!
      </h2>
      <h1 className="text-black font-medium text-lg">Order# {order._id}</h1>

      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item className="text-black">
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Shipping
              </h2>
              <p>
                <strong className="text-custom-orange font-medium">
                  Name:{" "}
                </strong>{" "}
                {order.user ? order.user.name : order.guestName || "Guest User"}
              </p>
              <p>
                <strong className="text-custom-orange font-medium">
                  Email:{" "}
                </strong>{" "}
                {order.user
                  ? order.user.email
                  : order.guestEmail || "No Email Provided"}
              </p>
              <p>
                <strong className="text-custom-orange font-medium">
                  Address:{" "}
                </strong>{" "}
                {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </p>
              <p>
                <strong className="text-custom-orange font-medium">
                  Phone Number:{" "}
                </strong>{" "}
                {order.shippingAddress.phoneNumber}
              </p>
              <br />
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item className="text-black">
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Payment Method
              </h2>
              <p>
                <strong className="text-custom-orange font-medium">
                  Method:{" "}
                </strong>{" "}
                {order.paymentMethod}
              </p>
              <br />
              {order.isPaid ? (
                <Message variant="success">Paid on {order.paidAt}</Message>
              ) : (
                <Message variant="danger">Not Paid Yet</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Order Items
              </h2>
              <br></br>
              {order.orderItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col md={1}>
                      <Image src={item.image} alt={item.name} fluid rounded />
                    </Col>
                    <Col>
                      <Link
                        className="text-black font-medium"
                        to={`/product/${item.product}`}
                      >
                        {item.name}
                        <br />

                        Size: {item.size}

                      </Link>
                    </Col>
                    <Col md={4} className="text-black font-medium">
                      {item.quantity} x {item.price} PKR ={" "}
                      {(item.quantity * item.price).toFixed(2)} PKR
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                  Order Summary
                </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col className="text-custom-orange font-bold">
                    Items Price:
                  </Col>
                  <Col className="text-black font-medium">
                    {order.itemsPrice} PKR
                  </Col>
                </Row>
                <Row>
                  <Col className="text-custom-orange font-bold">
                    Shipping Cost:
                  </Col>
                  <Col className="text-black font-medium">
                    {order.shippingCost} PKR
                  </Col>
                </Row>
                <Row>
                  <Col className="text-custom-orange font-bold">Tax:</Col>
                  <Col className="text-black font-medium">
                    {order.taxPrice} PKR
                  </Col>
                </Row>
                <Row>
                  <Col className="text-custom-orange font-bold">Total:</Col>
                  <Col className="text-black font-medium">
                    {order.totalPrice} PKR
                  </Col>
                </Row>
              </ListGroup.Item>

              {((!userInfo && guestToken) ||
                (userInfo && !userInfo.isAdmin)) && (
                <ListGroup.Item style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                  <Link
                    type="button"
                    className="animated-btn"
                    onClick={redirectToHome}
                  >
                    Continue Shopping
                  </Link>

                  <Link
                    type="button"
                    className="animated-btn"
                    onClick={downloadReceiptHandler}
                  >
                    Download Receipt
                  </Link>
                </ListGroup.Item>
              )}

              {loadingDeliver && <Loader />}
              {userInfo && userInfo.isAdmin && !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block"
                    onClick={deliverOrderHandler}
                  >
                    Mark As Delivered
                  </Button>
                </ListGroup.Item>
              )}

              {loadingPay && <Loader />}
              {userInfo && userInfo.isAdmin && !order.isPaid && (
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block"
                    onClick={payOrderHandler}
                  >
                    Mark As Paid
                  </Button>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrderScreen;
