import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Table, Button } from "react-bootstrap";
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import "../../assets/styles/product-edit-screen.css";
import {
  useGetProductsQuery,
  useAddNewProductMutation,
  useDeleteProductMutation,
} from "../../slices/productsApiSlice";
import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";

const ProductListScreen = () => {

  const { pageNumber } = useParams();

  const {
    data,
    isLoading: loadingProducts,
    error,
    refetch,
  } = useGetProductsQuery({ pageNumber });

  const [addNewProduct, { isLoading: addNewProductLoading }] =
    useAddNewProductMutation();

  const [deleteProduct, {isLoading: deleteProductLoading}] = useDeleteProductMutation();

  const addNewProductHandler = async () => {
    if (window.confirm("Are you sure? Want to add new product?")) {
      try {
        await addNewProduct().unwrap();
        refetch();
      } catch (err) {
        toast.error(err?.data.message || err.error);
      }
    }
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure, you want to delete?"))
    {
      try {
        await deleteProduct(id);
        toast.success("Product Deleted Successfully!");
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>All Listed Products</h1>
        </Col>
        <Col className="text-end">
          <Button className="btn-sm m-3" onClick={addNewProductHandler}>
            <FaPlus /> Add New Product
          </Button>
        </Col>
      </Row>

      {addNewProductLoading && <Loader />}
      {deleteProductLoading && <Loader />}

      {loadingProducts ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.fetchedProducts.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="mx-2 btn-sm">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm text-white"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;
