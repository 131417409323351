import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Zeebur",
  description: "We sell 100% original imported products",
  keywords:
    "shoes, sports shoes, casual shoes, buy shoes, nike shoes, adidas shoes, air jordans, jordans, sneakers, buy sneakers, buy jordans, buy air jordans, buy nike shoes, buy adidas shoes, buy shoes online, buy sneakers online, buy jordans online, buy air jordans online, buy nike shoes online, buy adidas shoes online, sketchers, puma shoes, buy puma shoes, new balance shoes, buy new balance shoes, converse shoes, buy converse shoes, vans shoes, buy vans shoes, reebok shoes, buy reebok shoes, under armour shoes, buy under armour shoes, gym shirts, buy gym shirts, gym shorts, buy gym shorts, gym gear, buy gym gear, gym equipment, buy gym equipment, gym clothes, buy gym clothes, gymshark, buy gymshark, gymshark clothes, buy gymshark clothes, gymshark gear, buy gymshark gear",
};

export default Meta;
