export const convertToDecimalTwoPoints = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
  // Calculate Items Price
  state.itemsPrice = convertToDecimalTwoPoints(
    state.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
  );

  // Calculate Shipping Price (If > 1 PKR, free shipping)
  state.shippingPrice = convertToDecimalTwoPoints(state.itemsPrice > 1 ? 250 : 250);

  // Calculate Tax Price (15% of Items Price)
  state.taxPrice = convertToDecimalTwoPoints(Number((0 * state.itemsPrice).toFixed(2)));

  // Calculate Total Price
  state.totalPrice = (
    Number(state.itemsPrice) +
    Number(state.shippingPrice) +
    Number(state.taxPrice)
  ).toFixed(2);

  localStorage.setItem('cart', JSON.stringify(state));

  return state;
};
