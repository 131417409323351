import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../utils/cartUitls";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { cartItems: [], shippingAddress: {}, paymentMethod: "PayPal" };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;

      // Check if the item with the same product ID and size is already in the cart
      const existingItem = state.cartItems.find(
        (x) => x._id === item._id && x.size === item.size
      );

      if (existingItem) {
        state.cartItems = state.cartItems.map((x) =>
          x._id === existingItem._id && x.size === existingItem.size ? item : x
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }

      return updateCart(state);
    },
    removeFromCart: (state, action) => {
      // Remove the item based on product ID and size
      state.cartItems = state.cartItems.filter(
        (x) => x._id !== action.payload._id || x.size !== action.payload.size
      );

      return updateCart(state);
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
      return updateCart(state);
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      return updateCart(state);
    },
    clearCart: (state, action) => {
      state.cartItems = [];
      return updateCart(state);
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
