import { useEffect } from "react";
import { toast } from "react-toastify";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, ListGroup, Image, Card } from "react-bootstrap";
import CheckoutSteps from "../components/CheckoutSteps";
import { useCreateNewOrderMutation } from "../slices/ordersApiSlice";
import { clearCart } from "../slices/cartSlice";
import { useGetGuestTokenQuery } from "../slices/guestSlice";
import "../assets/styles/index.css";

const PlaceOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const [createOrder, { isLoading, error }] = useCreateNewOrderMutation();

  const { data: guestUserToken } = useGetGuestTokenQuery();
  const guestToken = guestUserToken && guestUserToken.guestToken;

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate("/shipping");
    }
  }, [cart.shippingAddress.address, navigate]);

  const placeOrderHandler = async () => {
    try {
      const orderData = {
        orderItems: cart.cartItems.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          size: item.size, // Ensure size is included here
          sizeType: item.sizeType, // Include the size type to display correct format
          image: item.image || item.images[1],
          price: item.price,
          product: item.product || item._id,
        })),
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        taxPrice: cart.taxPrice,
        shippingCost: cart.shippingPrice,
        totalPrice: cart.totalPrice,
        guestName: cart.shippingAddress.name,
        guestEmail: cart.shippingAddress.email,
        guestToken: !userInfo ? guestToken : undefined,
      };

      const response = await createOrder(orderData).unwrap();
      if (response && response._id) {
        dispatch(clearCart());
        navigate(`/order/${response._id}`);
      } else {
        throw new Error("Order creation failed: No order ID");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while placing the order");
    }
  };

  const getDisplaySize = (item) => {
    if (item.category === "Shoes") {
      const sizeMapping = item.sizeMapping?.EU[item.size] || {};
      if (item.sizeType === "EU") {
        return `EU-${item.size}`;
      } else if (item.sizeType === "US") {
        return `US-${sizeMapping.US}`;
      } else if (item.sizeType === "UK") {
        return `UK-${sizeMapping.UK}`;
      } else {
        return `EU-${item.size}`; // Default to EU if sizeType is not set
      }
    }
    else {
      return item.size;
    }
  };

  return (
    <>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Shipping
              </h2>
              <p className="text-black">
                <strong className="text-custom-orange font-medium">
                  Name:{" "}
                </strong>
                {cart.shippingAddress.name}
              </p>
              <p className="text-black">
                <strong className="text-custom-orange font-medium">
                  Email:{" "}
                </strong>
                {cart.shippingAddress.email}
              </p>
              <p className="text-black">
                <strong className="text-custom-orange font-medium">
                  Address:{" "}
                </strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
              <p className="text-black">
                <strong className="text-custom-orange font-medium">
                  Phone Number:{" "}
                </strong>
                {cart.shippingAddress.phoneNumber}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Payment Method
              </h2>
              <p className="text-black">
                <strong className="text-custom-orange font-medium">
                  Method:{" "}
                </strong>
                {cart.paymentMethod}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                Order Items
              </h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                // Usage in PlaceOrderScreen
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.images[1]}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link
                            className="text-black font-medium"
                            to={`/product/${item._id}`}
                          >
                            {item.name}
                            <br></br>Size: {getDisplaySize(item)}{" "}
                            {/* Show size */}
                          </Link>
                        </Col>

                        <Col md={4} className="text-black font-medium">
                          {item.quantity} x {item.price} PKR ={" "}
                          {(item.quantity * item.price).toFixed(2)} PKR
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2 className="text-xl text-custom-orange font-semibold mb-[4px]">
                  Order Summary
                </h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="text-custom-orange font-bold ">Items:</Col>
                  <Col className="text-black font-medium">
                    {cart.itemsPrice} PKR
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="text-custom-orange font-bold ">Shipping:</Col>
                  <Col className="text-black font-medium">
                    {cart.shippingPrice} PKR
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="text-custom-orange font-bold ">Tax:</Col>
                  <Col className="text-black font-medium">
                    {cart.taxPrice} PKR
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="text-custom-orange font-bold ">Total:</Col>
                  <Col className="text-black font-medium">
                    {cart.totalPrice} PKR
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                {error && (
                  <Message variant="danger">{error?.data?.message}</Message>
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                <Link
                  type="button"
                  className="animated-btn"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Link>

                {isLoading && <Loader />}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderScreen;
