import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SearchBox = ({ closeMenu }) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      setKeyword("");
      closeMenu(); // Close the menu on search
      navigate(`/search/${keyword}`);
    } else {
      closeMenu(); // Close the menu if no search keyword
      navigate("/");
    }
  };

  return (
    <form
      onSubmit={searchSubmitHandler}
      className="flex justify-between gap-2 border-b-2 border-custom-orange mr-4 text-black"
    >
      <input
        type="text"
        name="q"
        value={keyword}
        placeholder="Search Products..."
        className="w-[20rem] outline-none"
        onChange={(e) => setKeyword(e.target.value)}
      />
      <button
        type="submit"
        className="hover:scale-105 transition-all"
      >
        <FaSearch className="text-custom-orange" />
      </button>
    </form>
  );
};

export default SearchBox;
